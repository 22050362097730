<template>
  <nav style="
  position: absolute;
  left: 0;
  right: 0;
  height: 80px;
  margin: 0;">
    <router-link class="design" active-class="active" to="/">Add a Hat</router-link> 
    <router-link class="design" active-class="active" to="/about">About</router-link>
  </nav>

  <router-view style="
  position: absolute; 
  top: 90px;
  bottom: 100px;
  left:10px; 
  right:10px; 
  padding: 10px;
  margin-left: auto; 
  margin-right: auto;
  margin-top: auto;
  overflow-y: scroll;
  "/>

  <button style="
  position: absolute; 
  bottom: 10px;
  left:10px;
  right:10px;
  height: 80px;
  margin-left:auto;
  margin-right:auto;
  background-color: var(--monad-purple);" 
  :onclick="goToMonad"
  class="ice-button w-button">Check Out Monad</button>
</template>

<script>
export default{
  name: 'HatAdding',
  methods: {
    goToMonad() {
      window.location.href = "https://www.monad.xyz/";
    }
  }
}
</script>

<style>
:root {
    --white-rabbit: #f7ede8;
    --monad-blue: #200052;
    --wine: #61004f;
    --white: white;
    --electric-ice: #5feddf;
    --blue-tint-2: #3d2487;
    --monad-purple: #836ef9;
    --blue-tint: #ccc4fc;
    --berry: #a0055d;
    --relume-library-lite-rl-white: white;
    --relume-library-lite-rl-black: black;
}

.active {
  color: white;
  background-color: var(--monad-purple);
}

.design {
  color: var(--monad-purple);
  margin: 5px;
  padding: 10px;
  font-size: 15px;
  font-family: sans-serif;
  border-radius: 4px;
  text-decoration: none;
}

.design:hover {
  color: white;
  background-color: var(--monad-purple);
  transition-duration: 0.2s;
}

body {
    margin: 0;
    display: flex;
    justify-content: center;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
}

nav a.router-link-exact-active {
  color: white;
}

::-webkit-scrollbar {
  display: block;
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-button       {
  width: 0;
  height: 0;
}
::-webkit-scrollbar-track { 
  background: transparent;
}
::-webkit-scrollbar-thumb {
    background-color: rgba(32, 0, 82, 0.5);
    border-radius: 10px;
    border-right: none;
    border-left: none;
}
</style>
